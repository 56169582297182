import {
  Button, DialogActions, DialogContent
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { translate } from 'utils';
import { ModalHeader } from './_ModalHeader';

export const DefaultModal = ({
  onClose, content, title, withTranslate, className
}) => (
  <>
    <ModalHeader className={className} onClose={onClose}>
      {withTranslate ? translate(title) : title}
    </ModalHeader>

    <DialogContent style={{ maxWidth: '500px', marginTop: 30 }}>
      {content}
    </DialogContent>

    <DialogActions>
      <Button
        color="primary"
        variant="text"
        onClick={onClose}
      >
        {translate('button.close')}
      </Button>
    </DialogActions>
  </>
);

DefaultModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.shape({})
  ]),
  title: PropTypes.string,
  withTranslate: PropTypes.bool
};

DefaultModal.defaultProps = {
  content: null,
  title: 'common.defaultModal',
  withTranslate: true
};