import i18n from 'i18next';
import { userStore } from 'stores';
import { translate } from '../index';
import { FilterHelper } from '.';

import { httpSettings } from './request';

const getErrorTranslation = (code, message) => {
  let error = String(message);
  if (error.indexOf(':') !== -1) {
    error = error.split(':')[1].trim();
  }

  if (i18n.exists(`errors.${error}`)) {
    return i18n.t(`errors.${error}`);
  }

  return `${i18n.t('errors.UNCATCHED_ERROR')} (${code})`;
};
export class HTTPError extends Error {
  constructor(code, message) {
    super(getErrorTranslation(code, message));
    this.statusCode = code;
  }
}

const processErrorList = (errorList) => {
  let processedMessage = '';
  errorList && errorList.map((errorItem) => {
    processedMessage += `- ${errorItem} <br />`;
    return processedMessage;
  });
  return processedMessage;
};

export const processError = (error) => {
  if (error) {
    if (!Array.isArray(error) && typeof error === 'object') {
      let processedMessage = '';
      Object.keys(error).forEach((key) => {
        if (Array.isArray(error[key])) {
          processedMessage += `<b>${key}:</b><br /> ${processErrorList(error[key])} <br />`;
          return processedMessage;
        }

        processedMessage = error[key].message;
        return processedMessage;
      });

      if (processedMessage) {
        return processedMessage;
      }
      return error;
    }
    if (Array.isArray(error)) {
      return processErrorList(error);
    }
    return error;
  }
  return error;
};

const defaultResponseHandler = async (response) => {
  const { status, statusText } = response;

  switch (status) {
  case 200:
    if (userStore.isConnected && userStore.isOffline) {
      await userStore.setOffline(false);
    }
    return response.json();
  case 201:
  case 202:
  case 204:
    if (userStore.isConnected && userStore.isOffline) {
      await userStore.setOffline(false);
    }
    return true;
  case 226:
    // Specific case for files
    return response;
  case 401:
    if (userStore.isConnected) {
      userStore.refreshToken()
        .then(() => setTimeout(() => window.location.reload(), 2000))
        .catch(() => userStore.disconnectUser()
          .then(() => userStore.keycloakLogin()));

      throw new Error(translate('errors.sessionRenewed'));
    }

    FilterHelper.deleteStoredFilters();
    userStore.keycloakLogin();
    throw new Error(translate('errors.sessionExpired'));
  case 403:
    throw new Error(translate('errors.noRights'));
  case 412:
    return response.json().then((error) => {
      throw processError(error);
    });
  default:
    throw new HTTPError(status, statusText);
  }
};

const defaultErrorHandler = (error) => {
  throw processError(error);
};

const GET = (url, headers, internalAPI = true, withAuth = true) => fetch(url, httpSettings({
  headers,
  method: 'GET'
}, internalAPI, withAuth))
  .then(defaultResponseHandler)
  .catch(defaultErrorHandler);

const POST = (url, body = {}, headers = null, signal = null, withAuth = true) => fetch(url, httpSettings({
  method: 'POST',
  headers,
  body,
  signal
}, withAuth))
  .then(defaultResponseHandler)
  .catch(defaultErrorHandler);

const PUT = (url, body = {}, headers = null, withAuth = true) => fetch(url, httpSettings({
  method: 'PUT',
  headers,
  body
}, withAuth))
  .then(defaultResponseHandler)
  .catch(defaultErrorHandler);

const PATCH = (url, body = {}, headers = null, withAuth = true) => fetch(url, httpSettings({
  method: 'PATCH',
  headers,
  body
}, withAuth))
  .then(defaultResponseHandler)
  .catch(defaultErrorHandler);

const DELETE = (url, body = {}, headers = null, withAuth = true) => fetch(url, httpSettings({
  method: 'DELETE',
  headers,
  body
}, withAuth))
  .then(defaultResponseHandler)
  .catch(defaultErrorHandler);

// Regroup all functions inside a single const to simplify
export const RequestHelper = {
  GET,
  POST,
  PUT,
  PATCH,
  DELETE
};