import { RequestHelper } from 'utils';
import { API_ROUTES } from 'utils/constants';

// GET
const healthcheck = () => RequestHelper.GET(API_ROUTES.HEALTH_CHECK, {}, false);

const ping = () => fetch(API_ROUTES.PING).then((response) => response);

export const HealthcheckService = {
  healthcheck,
  ping
};