/* eslint-disable camelcase */
import { faSpinner, faStoreAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DialogContent, Grid, TextField, Typography
} from '@mui/material';
import { SelectItemWithLeftElement } from 'components/_commons/Form';
import { FormInputField } from 'components/_commons/Form/Inputs';
import FormSelectField from 'components/_commons/Form/Inputs/SelectField/FormSelectField';
import ItemOption from 'components/_commons/Form/ItemOption/ItemOption';
import { CloseModalButton } from 'components/_commons/Modals/CloseModalButton';
import { ModalHeader } from 'components/_commons/Modals/_ModalHeader';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { FormContainer, useForm } from 'react-hook-form-mui';
import { CompanyService, CountryService, LegalStatusService } from 'services';
import { i18nStore } from 'stores';
import { checkRegexMismatch, translate } from 'utils';
import { countryItems, STRUCTURE_TYPE } from 'utils/constants';
import { CustomActionButtons } from './_CustomActionButtons';

const LegalStatusValue = ({ data, innerProps }) => (
  <ItemOption {...innerProps} style={{ gridArea: ' 1/1/2/3' }}>
    <Typography style={{ marginLeft: '0.8rem' }}>
      {data.name ?? null}
    </Typography>
  </ItemOption>
);

const LegalStatusOption = ({ data, innerProps }) => (
  <ItemOption {...innerProps} isInsideSelect noPadding>
    <Typography style={{ marginLeft: '0.8rem' }}>
      {data.name}
    </Typography>
  </ItemOption>
);

export const CreateCompanyModal = ({
  onConfirm,
  onClose,
  closeOnSubmit,
  defaultValues,
  notEditable
}) => {
  const { currentLanguage } = i18nStore;
  const { enqueueSnackbar } = useSnackbar();
  const [isSearchingSiren, setIsSearchingSiren] = useState(false);
  const values = {
    ...defaultValues,
    country: defaultValues.country ?? countryItems.france,
    legalStatus: defaultValues.legalStatus ? defaultValues.legalStatus : null
  };
  const formContext = useForm({ defaultValues: values });
  const { watch, setValue } = formContext;
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchingCountries, setIsFetchingCountries] = useState(false);
  const [countriesSelectItems, setCountriesSelectItems] = useState([]);
  // The current values for the identifier fields
  const [identifierFields, setIdentifierFields] = useState(values?.identifiers || []);
  // The available identifier fields for the selected country
  const [availableFields, setAvailableFields] = useState([]);
  const [legalStatuses, setLegalStatuses] = useState([]);
  const [areAllIdentifiersValid, setAreAllIdentifiersValid] = useState(false);
  const [isErrorDisplay, setIsErrorDisplay] = useState(false);

  const isIdentifierFieldValid = useCallback(({ value, rule, required }) => {
    const isExpressionValid = !checkRegexMismatch(rule?.format, value);
    if (required) {
      return value && isExpressionValid;
    }
    return !value || isExpressionValid;
  }, [checkRegexMismatch]);

  // Check identifier fields validity
  useEffect(() => {
    setAreAllIdentifiersValid(identifierFields.every((identifierField) => isIdentifierFieldValid(identifierField)));
  }, [identifierFields, isIdentifierFieldValid]);

  useEffect(() => {
    setIsErrorDisplay(formContext?.formState?.isSubmitted || identifierFields.some((field) => field.value));
  }, [formContext?.formState]);

  const updateIdentifiers = useCallback((newFields) => {
    // Replace the existing fields with the new ones
    setIdentifierFields(availableFields.map((field) => {
      const newField = newFields?.find((f) => f.idField === field.idField);
      if (newField) {
        return {
          ...field,
          value: newField.value
        };
      }
      const existingField = identifierFields?.find((f) => f.idField === field.idField);
      if (existingField) {
        return {
          ...field,
          value: existingField.value
        };
      }
      return field;
    }));
  }, [identifierFields, availableFields]);

  const generateTVAFromSirenIfEmpty = useCallback((sirenField) => {
    let tvaIntraField = availableFields?.find((idField) => idField.name === 'TVA intracommunautaire');
    const existingTvaIntra = watch('identifiers')?.find((idField) => idField.name === 'TVA intracommunautaire');
    if (tvaIntraField) {
      let tvaCode = `${(12 + 3 * (Number(sirenField.value) % 97)) % 97}`;
      if (tvaCode.length === 1) {
        tvaCode = `0${tvaCode}`;
      }
      const tvaIntraValue = `FR ${tvaCode}${sirenField.value}`;
      tvaIntraField = {
        ...tvaIntraField,
        value: tvaIntraValue
      };
      updateIdentifiers([sirenField, tvaIntraField]);
    } else if (existingTvaIntra?.value) {
      tvaIntraField = {
        ...tvaIntraField,
        value: existingTvaIntra?.value
      };
      updateIdentifiers([sirenField, tvaIntraField]);
    } else {
      updateIdentifiers([sirenField]);
    }
  }, [defaultValues, availableFields, updateIdentifiers]);

  const getCompanyBySiren = useCallback((sirenField) => {
    generateTVAFromSirenIfEmpty(sirenField);
    setIsSearchingSiren(true);
    CompanyService.getCompanyBySiren(sirenField.value).then((response) => {
      if (response.header.statut === 200) {
        const tempCompany = response.uniteLegale.periodesUniteLegale[0].denominationUniteLegale;
        const tempLegalStatus = legalStatuses?.find(
          (stat) => stat.name === response.uniteLegale.periodesUniteLegale[0].categorieJuridiqueUniteLegale
        );
        if (!watch('name')) {
          setValue('name', tempCompany);
        }
        if (!watch('legalStatus')) {
          tempLegalStatus && setValue('legalStatus', tempLegalStatus);
        }
      } else {
        enqueueSnackbar(response.header.message, { variant: 'warning', autoHideDuration: 5000 });
      }
    }).catch(() => {
      enqueueSnackbar(translate('errors.noMatchingSiren'), { variant: 'warning', autoHideDuration: 5000 });
    }).finally(() => setIsSearchingSiren(false));
    // eslint-disable-next-line
  }, [enqueueSnackbar, availableFields, legalStatuses, updateIdentifiers, generateTVAFromSirenIfEmpty]);

  const handleIdentifierValueChange = useCallback((identifierField, cascade = false) => {
    if (identifierField.primary && !checkRegexMismatch(identifierField.rule?.format, identifierField.value)
      && watch('country')
      && watch('country').countryCode === 'fr'
      && cascade
    ) {
      getCompanyBySiren(identifierField);
    } else {
      updateIdentifiers([identifierField]);
    }
  }, [getCompanyBySiren, updateIdentifiers]);

  useEffect(() => {
    // When the available identifier fields change, initialise them with the current data
    availableFields.forEach((idFieldToInit) => {
      const existingField = watch('identifiers')?.find((idField) => idField.idField === idFieldToInit.idField);
      // If the field is already defined with a value, reload it to update other fields
      if (existingField && existingField.value) {
        handleIdentifierValueChange(existingField);
      } else {
        handleIdentifierValueChange(idFieldToInit);
      }
    });
    // eslint-disable-next-line
  }, [availableFields]);

  const fetchLegalStatusList = useCallback(() => {
    LegalStatusService.getLegalStatusByCountryId(watch('country').value)
      // eslint-disable-next-line no-nested-ternary
      .then((resp) => setLegalStatuses(resp.sort((a, b) => a.name?.localeCompare(b.name))));
  }, [setLegalStatuses]);

  const loadCountryData = useCallback((country) => {
    fetchLegalStatusList();
    const companyIdentifierFields = country.identifiers.filter(
      (identifierField) => identifierField.type === STRUCTURE_TYPE.COMPANY
    );
    setAvailableFields(companyIdentifierFields);
  }, [fetchLegalStatusList, setAvailableFields]);

  const handleChangeSelectedCountry = useCallback((countryItem) => {
    setValue('country', countryItem);
    setIsLoading(true);
    CountryService.getCountryById(countryItem.value)
      .then(loadCountryData)
      .then(() => setValue('legalStatus', null))
      .finally(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 300);
      });
  }, [setIsLoading, setValue, loadCountryData]);

  useEffect(() => {
    setIsFetchingCountries(true);
    CountryService.getCountrySelectItems()
      .then((json) => {
        setCountriesSelectItems(json);
      })
      .finally(() => setIsFetchingCountries(false));
  }, [setCountriesSelectItems, setIsFetchingCountries]);

  useEffect(() => {
    setIsLoading(true);
    CountryService.getCountryById(watch('country').value)
      .then(loadCountryData)
      .finally(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 300);
      });
    // eslint-disable-next-line
  }, [defaultValues]);

  const handleValidateModal = useCallback(() => {
    if (!areAllIdentifiersValid) {
      return;
    }

    onConfirm({
      ...formContext.getValues(),
      identifiers: identifierFields,
      id: defaultValues.id || null
    });

    if (closeOnSubmit) {
      onClose();
    }
  }, [onConfirm, identifierFields, defaultValues.id, closeOnSubmit, onClose, areAllIdentifiersValid]);

  const customFilter = useCallback((legalStatus, search) => {
    if (search && legalStatus?.data.name) {
      return legalStatus.data.name.toUpperCase()
        .includes(search.toUpperCase());
    }
    return true;
  }, []);

  return (
    <FormContainer
      formContext={formContext}
      FormProps={{
        autoComplete: 'off',
        name: 'companyForm',
        style: { overflow: 'auto' }
      }}
      onSuccess={handleValidateModal}
    >
      <ModalHeader onClose={onClose}>
        <FontAwesomeIcon icon={faStoreAlt} />
        <Typography component="span">
          {(notEditable && translate('modalCompany.viewCompany')) || (defaultValues && defaultValues.name
            ? translate('modalCompany.editCompany')
            : translate('modalCompany.newCompany'))}
        </Typography>
      </ModalHeader>

      <DialogContent style={{ width: '500px' }}>
        <Grid container direction="column">
          <Typography margin={1} variant="h6">
            {translate('common.country')}
          </Typography>
          <SelectItemWithLeftElement
            isDisabled={notEditable}
            isFlagElement
            isLoading={isFetchingCountries}
            label="common.selectCountry"
            name="country"
            options={countriesSelectItems}
            value={watch('country')}
            onChange={handleChangeSelectedCountry}
          />

          <Typography margin={1} variant="h6">
            {translate('common.company')}
          </Typography>

          {isLoading ? (
            <Grid item>
              <FontAwesomeIcon icon={faSpinner} spin />
              {translate('common.loading')}
            </Grid>
          )
            : (identifierFields.map((identifierField) => {
              const {
                idField, value, name, required, rule
              } = identifierField;
              return (
                <TextField
                  disabled={notEditable}
                  error={isErrorDisplay && !isIdentifierFieldValid(identifierField)}
                  helperText={(rule?.format && rule?.translations?.find((val) => val.code === currentLanguage)?.label) ?? ''}
                  inputProps={rule?.format ? { pattern: rule?.format } : {}}
                  key={idField}
                  label={name}
                  name={name}
                  required={required}
                  value={value ?? ''}
                  variant="standard"
                  onChange={(val) => handleIdentifierValueChange({ ...identifierField, value: val.target.value }, true)}
                />
              );
            }))}

          <FormInputField
            disabled={notEditable}
            endAdornment={(isSearchingSiren && <FontAwesomeIcon icon={faSpinner} spin />)}
            label={translate('common.corporateName')}
            name="name"
            required
          />

          <div style={{ margin: '1rem 0' }} />

          <FormSelectField
            components={{ Option: LegalStatusOption, SingleValue: LegalStatusValue }}
            disabled={notEditable}
            disablePortal={false}
            filterOption={customFilter}
            getOptionLabel={(option) => option.name}
            id="legalStatus"
            label={translate('common.legalStatus')}
            menuPlacement="top"
            name="legalStatus"
            options={legalStatuses ?? []}
            required
            variant="outlined"
          />
        </Grid>
      </DialogContent>

      {notEditable
        ? (
          <CloseModalButton onClose={onClose} />
        )
        : (
          <CustomActionButtons
            disabled={isErrorDisplay && !areAllIdentifiersValid}
            isValidated={defaultValues.validated}
            submitLabelKey={defaultValues?.name ? 'button.save' : 'common.create'}
            onClose={onClose}
          />
        )}
    </FormContainer>
  );
};

CreateCompanyModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  closeOnSubmit: PropTypes.bool,
  defaultValues: PropTypes.shape({}),
  notEditable: PropTypes.bool
};

CreateCompanyModal.defaultProps = {
  closeOnSubmit: true,
  defaultValues: {},
  notEditable: false
};